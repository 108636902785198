



































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartRunningAsymmetry',
  props: {
    asymmetry: {
      type: Object,
      required: true
    }
  }
});
