






















// Import vendors ----------------------------------------------------------------------------------
import {
  defineComponent,
  ref,
  watch,
  onBeforeMount,
  onBeforeUnmount,
  computed,
  provide
} from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import AlertError from '@/components/alerts/AlertError.vue';
import PatientResults from '@/components/patient/results/PatientResults.vue';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModule } from '@/plugins/podocore';
// Import utils ------------------------------------------------------------------------------------
import { useRoute } from '@/utils/router.utils';
import { useSingleAnalysis } from '@/utils/single-analysis.utils';
// Import config -----------------------------------------------------------------------------------
import { apiConfig } from '@/config/api.config';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'PagePatientResults',
  components: {
    AlertError,
    PatientResults
  },
  setup(_, { root }) {
    provide('single-analysis', ref(null));
    const { params, name } = useRoute();
    const { setSingleAnalysis } = useSingleAnalysis();

    const busModule = usePodocoreModule('bus');
    const requestModule = usePodocoreModule('request');

    const analysisRequest = ref<any>(null);
    const scenarioRequest = ref<any>(null);

    function configureRequest(cuid: string) {
      if (analysisRequest.value?.cancel) analysisRequest.value.cancel();
      analysisRequest.value = requestModule.useAuthenticatedRequest(`${apiConfig.default}/analysis/${cuid}`);
      analysisRequest.value.request();
    }

    const scenarioCuid = computed(() => analysisRequest.value?.data?.scenarioCuid);

    watch(scenarioCuid, (value) => {
      if (value) {
        scenarioRequest.value = requestModule.useAuthenticatedRequest(
          `${apiConfig.default}/scenarios/${analysisRequest.value.data.scenarioCuid}`
        );
        scenarioRequest.value.request();
      }
    });

    const scenario = computed(() => scenarioRequest.value?.data);

    const analysisLoaded = computed(() => !analysisRequest.value?.isPending && !analysisRequest.value?.error);

    watch(analysisLoaded, (value) => {
      if (value) setSingleAnalysis(analysisRequest.value.data);
    });

    watch(params, () => {
      configureRequest(params.value.analysisCuid as string);
    });

    onBeforeMount(() => {
      configureRequest(params.value.analysisCuid as string);
    });

    onBeforeUnmount(() => {
      analysisRequest.value.cancel();
      scenarioRequest.value.cancel();
    });

    // busModule.useEventSubscriber(busModule.events.analysisConditionsPatched, () => {
    //   analysisRequest.value.request();
    // });

    busModule.useEventSubscriber(busModule.events.analysisDeleted, () => {
      if (name.value != 'patient') {
        root.$router.push({ path: `/patients/${params.value.cuid}` });
      }
    });

    return {
      analysisRequest,
      scenarioRequest,
      scenario
    };
  }
});
