var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isPending)?_c('VSkeletonLoader',{staticClass:"mb-5",attrs:{"type":"image","height":"250"}}):(_vm.error)?_c('AlertError',{staticClass:"mb-5",attrs:{"error":_vm.error}}):(_vm.videos && _vm.videos.length)?_c('div',{staticClass:"summary-card mb-5"},[_c('div',{staticClass:"chart-card__title"},[_c('span',[_vm._v(_vm._s(_vm.$t('commons.standards.attached-media')))]),(!_vm.isPending && !_vm.error)?_c('VMenu',{attrs:{"content-class":"elevation-5","offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('VBtn',_vm._g({attrs:{"color":"white","depressed":"","icon":""}},menu),[_c('GlobalIcon',{attrs:{"icon":"ellipsis-h","color":"grey","size":"16"}})],1)]}}],null,false,4224310706)},[(_vm.$can('delete', 'video'))?_c('VList',{staticClass:"pa-0",attrs:{"dense":""}},_vm._l((_vm.videos),function(video,index){return _c('VListItem',{key:index,on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.openDeleteMedia(video.cuid)}}},[_c('div',{staticClass:"list-menu-item"},[_c('GlobalIcon',{staticClass:"mr-1",attrs:{"icon":"trash-alt","color":"warning","size":"11"}}),_c('span',{staticClass:"warning--text font-weight-semibold caption"},[_vm._v(" "+_vm._s(_vm.$tc('commons.sentences.remove-media'))+" "),(
                    video._scenarioStep.descriptionKey !== 'walking' &&
                    video._scenarioStep.descriptionKey !== 'running'
                  )?[_vm._v(_vm._s(_vm.$t(_vm.getFeet(video._scenarioStep.descriptionKey)))+" ")]:_vm._e()],2)],1)])}),1):_vm._e()],1):_vm._e()],1),_c('div',{staticClass:"chart-card__results patient__result__videos"},_vm._l((_vm.videos),function(video,index){return _c('VSheet',{key:index,style:(_vm.videos.length > 1 ? 'width: 50%' : 'width: -webkit-fill-available;'),attrs:{"color":video.latestStatus.status === 'completed' ? 'black' : 'white'}},[_c('div',{staticClass:"patient__result__videos__preview relative"},[(video.latestStatus.status === 'completed')?[(!_vm.player[index])?[_c('VImg',{staticClass:"pointer",attrs:{"src":video.processed.thumbnails[0]._signedS3Url,"height":"250"},nativeOn:{"click":function($event){_vm.player[index] = true}}}),_c('GlobalIcon',{staticClass:"absolute-centered",attrs:{"icon":"circle-play","size":"60","color":"white"}})]:_c('PatientMediasVideo',{attrs:{"video":video,"autoplay":""}})]:(video.latestStatus.status === 'awaitingUpload')?_c('div',{staticClass:"pt-2"},[(_vm.minutesNextUpload(video) <= 60)?[_c('VProgressLinear',{staticClass:"mb-2",attrs:{"height":"8","indeterminate":""}}),_c('div',{staticClass:"progressbar-status"},[_vm._v(_vm._s(_vm.$t('commons.actions.loading-media') + '...'))])]:_c('div',{staticClass:"progressbar-status"},[_vm._v(" "+_vm._s(_vm.$t('commons.actions.loading-media-error'))+" ")])],2):_c('div',{staticClass:"pt-2"},[_c('div',{staticClass:"d-flex align-center mb-2"},[_c('VProgressLinear',{staticClass:"mr-2",attrs:{"height":"8","indeterminate":video.processingJobProgression <= 0,"value":video.processingJobProgression}}),_c('span',{staticClass:"processing-percent"},[_vm._v(_vm._s(video.processingJobProgression + '%'))])],1),_c('div',{staticClass:"progressbar-status"},[_vm._v(" "+_vm._s(_vm.$t('commons.actions.rendering-in-progress') + '...')+" ")])])],2),(
            video._scenarioStep.descriptionKey !== 'walking' &&
            video._scenarioStep.descriptionKey !== 'running' &&
            video._scenarioStep.descriptionKey !== 'counter-movement'
          )?_c('div',{staticClass:"white pt-2"},[_c('span',{staticClass:"badge white--text",class:video._scenarioStep.descriptionKey.endsWith('left') ? 'badge--left' : 'badge--right'},[_vm._v(" "+_vm._s(_vm.$t(_vm.getFeet(video._scenarioStep.descriptionKey)).toUpperCase())+" ")])]):_vm._e()])}),1)]):_vm._e(),_c('DialogMediaDelete')],1)}
var staticRenderFns = []

export { render, staticRenderFns }