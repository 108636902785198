













// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartContainer from '@/components/charts/ChartContainer.vue';
import ChartStandardGaugeSections from '@/components/charts/standard/ChartStandardGaugeSections.vue';
// Import helpers ----------------------------------------------------------------------------------
import { getUnit, EValueTypeUnit } from '@/helpers/helped-chart-functions.helper';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartWalkingContainerSpeed',
  components: {
    ChartStandardGaugeSections,
    ChartContainer
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    norms: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    const valid = computed(() => properties.displayMetrics.aggregates.walking_speed);
    const unit = computed(() => getUnit(EValueTypeUnit.Speed));
    const normsType = computed(() => {
      return {
        base: [
          properties.norms.walking_speed.bounds.neutral.min,
          properties.norms.walking_speed.bounds.neutral.max
        ]
      };
    });

    return {
      valid,
      unit,
      normsType
    };
  }
});
