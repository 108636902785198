




























































// Import vendors ----------------------------------------------------------------------------------
import {
  defineComponent,
  ref,
  readonly,
  computed,
  provide,
  toRefs,
  PropType,
  ComputedRef
} from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import PatientResultsHeader from './PatientResultsHeader.vue';
import PatientResultsWalking from './PatientResultsWalking.vue';
import PatientResultsRehab from './PatientResultsRehab.vue';
import PatientResultsRunning from './PatientResultsRunning.vue';
import PatientResultsFooter from './PatientResultsFooter.vue';
import DialogAnalysisPathologyCustomSelect from '@/components/dialogs/DialogAnalysisPathologyCustomSelect.vue';
import DialogAnalysisConditionsPainsUpdate from '@/components/dialogs/DialogAnalysisConditionsPainsUpdate.vue';
import DialogAnalysisConditionsWalkingAidsUpdate from '@/components/dialogs/DialogAnalysisConditionsWalkingAidsUpdate.vue';
import DialogAnalysisConditionsShoesTypeUpdate from '@/components/dialogs/DialogAnalysisConditionsShoesTypeUpdate.vue';
// Import helpers ----------------------------------------------------------------------------------
import { isValidAnalysis, isDegradedAnalysis } from '@/helpers/helped-functions.helper';
// Import utils ------------------------------------------------------------------------------------
import { usePatient } from '@/utils/patient.utils';
// Import types ------------------------------------------------------------------------------------
import { EPathology } from '@/components/patient/results/PatientResultsHeader.vue';
import type { AnalysisEntityCompleted } from '@digitsole/blackburn-entities/dist/entities/analysis/analysis-completed.entity';
// -------------------------------------------------------------------------------------------------

export enum EResultsTab {
  General = 'general',
  Advanced = 'advanced'
}
export enum EPathologyFilter {
  default = 'default',
  reAthleticism = 're-athleticism',
  neurological = 'neurological',
  traumatology = 'traumatology',
  custom = 'custom'
}

export default defineComponent({
  name: 'PatientResults',
  components: {
    PatientResultsHeader,
    PatientResultsWalking,
    PatientResultsRunning,
    PatientResultsRehab,
    PatientResultsFooter,
    DialogAnalysisPathologyCustomSelect,
    DialogAnalysisConditionsPainsUpdate,
    DialogAnalysisConditionsWalkingAidsUpdate,
    DialogAnalysisConditionsShoesTypeUpdate
  },
  props: {
    analysis: {
      type: Object as PropType<AnalysisEntityCompleted>,
      required: true
    },
    scenario: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    const { data: patient } = usePatient();
    const { analysis } = toRefs(properties);
    provide('analysis', readonly(analysis));

    const analysisType = computed(() => properties.scenario.key);

    const isWalking = computed(() => analysisType.value.startsWith('walking'));
    const isRunning = computed(() => analysisType.value.startsWith('running'));
    const isRehab = computed(() => analysisType.value.startsWith('jumping'));

    const displayMetrics = computed(() => (analysis.value as any)?.display_metrics);
    const aggregates = computed(() => (analysis.value as any)?.metrics?.aggregates);
    const segments = computed(() => (analysis.value as any)?.metrics?.segment);
    const norms = computed(() => (analysis.value as any)?.metrics?.norms);
    const validAnalysis: ComputedRef<boolean> = computed(() => isValidAnalysis(analysis.value));
    const degradedAnalysis: ComputedRef<boolean> = computed(() => isDegradedAnalysis(analysis.value));

    // Results tab
    const resultsTab = ref<EResultsTab>(EResultsTab.General);
    function setResultsTab(tab: EResultsTab) {
      resultsTab.value = tab;
    }

    // Walking pathology
    const selectedPathology = ref(EPathology.Default);
    function setSelectedPathology(pathology: EPathology) {
      selectedPathology.value = pathology;
      resultsTab.value = EResultsTab.General;
    }
    // Walking custom charts
    const customSelectedCharts = ref([]);
    function setSelectedCharts(data: any) {
      customSelectedCharts.value = data;
    }

    return {
      validAnalysis,
      degradedAnalysis,
      // Values
      selectedPathology,
      customSelectedCharts,
      resultsTab,
      patient,
      aggregates,
      segments,
      norms,
      displayMetrics,
      analysisType,
      isWalking,
      isRunning,
      isRehab,
      // Methods
      setResultsTab,
      setSelectedCharts,
      setSelectedPathology
    };
  }
});
