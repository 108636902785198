












// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartContainer from '@/components/charts/ChartContainer.vue';
import ChartRunningSpeedCadence from '@/components/charts/running/ChartRunningSpeedCadence.vue';
// Import utils ------------------------------------------------------------------------------------
import { useUnit } from '@/utils/unit.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartRunningContainerSpeedCadence',
  components: {
    ChartContainer,
    ChartRunningSpeedCadence
  },
  props: {
    segments: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    },
    scenario: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    const { currentUnit } = useUnit();

    const valid = computed(
      () =>
        !!properties.segments.running_step_cadence?.global?.avg &&
        !!properties.segments.running_speed?.global?.avg
    );

    return {
      currentUnit,
      valid
    };
  }
});
