






























// Import vendors ----------------------------------------------------------------------------------
import { defineComponent } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import PatientResultVideos from '@/components/patient/results/PatientResultVideos.vue';
import ChartWalkingContainerGroupBanner from '@/components/charts/walking/containers/ChartWalkingContainerGroupBanner.vue';
import ChartWalkingContainerGaitLine from '@/components/charts/walking/containers/ChartWalkingContainerGaitLine.vue';
import ChartWalkingContainerWalkProfile from '@/components/charts/walking/containers/ChartWalkingContainerWalkProfile.vue';
import ChartWalkingContainerPropulsionRatio from '@/components/charts/walking/containers/ChartWalkingContainerPropulsionRatio.vue';
import PatientResultSummary from '@/components/patient/results/PatientResultSummary.vue';
//
import ElementInterpretationManualBanner from '@/components/elements/ElementInterpretationManualBanner.vue';
// Import utils ------------------------------------------------------------------------------------
import { useInterpretationManual } from '@/utils/interpretation-manual.utils';
import { usePatient } from '@/utils/patient.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'PatientResultsWalkingGeneral',
  components: {
    PatientResultVideos,
    PatientResultSummary,
    ChartWalkingContainerGroupBanner,
    ChartWalkingContainerWalkProfile,
    ChartWalkingContainerGaitLine,
    ChartWalkingContainerPropulsionRatio,
    ElementInterpretationManualBanner
  },
  props: {
    analysis: {
      type: Object,
      required: true
    },
    aggregates: {
      type: Object,
      required: true
    },
    norms: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    }
  },
  setup() {
    const { data: patient } = usePatient();
    const { dontShowManualInterpretation } = useInterpretationManual();

    return {
      // Values
      patient,
      // Flags
      dontShowManualInterpretation
    };
  }
});
