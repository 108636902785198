











































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartContainer from '@/components/charts/ChartContainer.vue';
import ChartStandardSlider from '@/components/charts/standard/ChartStandardSlider.vue';
import ChartWalkingCircumduction from '@/components/charts/walking/ChartWalkingCircumduction.vue';
// Import helpers ----------------------------------------------------------------------------------
import { getUnit } from '@/helpers/helped-chart-functions.helper';
// Import utils ------------------------------------------------------------------------------------
import { useUnit } from '@/utils/unit.utils';
// Import types ------------------------------------------------------------------------------------
import { EValueTypeUnit } from '@/helpers/helped-chart-functions.helper';
import { Entity } from '@/plugins/podocore/helpers/repositories.helper';
import { Patient } from '@/plugins/podocore/repositories/patients.repository';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartWalkingContainerCircumduction',
  components: {
    ChartContainer,
    ChartStandardSlider,
    ChartWalkingCircumduction
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    norms: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    },
    vertical: {
      type: Boolean
    }
  },
  setup(properties) {
    const { isImperial } = useUnit().currentUnit();

    const valid = computed(() => properties.displayMetrics.aggregates.circumduction);
    const unit = getUnit(EValueTypeUnit.Circumduction);

    return {
      // Valid
      valid,
      // Values
      isImperial,
      unit
    };
  }
});
