














































































// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed } from '@vue/composition-api';
// Import helpers ----------------------------------------------------------------------------------
import { getUnit, EValueTypeUnit } from '@/helpers/helped-chart-functions.helper';
// Import utils ------------------------------------------------------------------------------------
import { useUnit } from '@/utils/unit.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartWalkingClearance',
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    norms: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    const { isImperial } = useUnit().currentUnit();

    const data = computed(() => {
      return {
        left: {
          color: '#FFBE13',
          textColor: '#B36200',
          height: properties.aggregates.left_foot[isImperial ? 'imp_avg' : 'avg']
        },
        right: {
          color: '#2462FD',
          textColor: '#0E2D86',
          height: properties.aggregates.right_foot[isImperial ? 'imp_avg' : 'avg']
        }
      };
    });

    function getValueLevel(value: string) {
      switch (value) {
        case 'good':
          return '#25C090';
        case 'warning':
          return '#FFBE13';
        case 'bad':
          return '#FF5E13';
        default:
          return '#25C090';
      }
    }

    const unit = computed(() => getUnit(EValueTypeUnit.Clearance));

    return {
      data,
      getValueLevel,
      unit
    };
  }
});
