var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"patient__results"},[(_vm.validAnalysis)?_c('div',[_c('PatientResultsHeader',_vm._b({staticClass:"mb-5",on:{"setResultsTab":function (tab) { return _vm.setResultsTab(tab); },"setSelectedPathology":function (pathology) { return _vm.setSelectedPathology(pathology); }}},'PatientResultsHeader',{ analysis: _vm.analysis, patient: _vm.patient, degradedAnalysis: _vm.degradedAnalysis, scenario: _vm.scenario },false))],1):_vm._e(),_c('div',{staticClass:"patient__results__analysis"},[(_vm.isWalking)?_c('PatientResultsWalking',_vm._b({},'PatientResultsWalking',{
          analysis: _vm.analysis,
          resultsTab: _vm.resultsTab,
          selectedPathology: _vm.selectedPathology,
          degradedAnalysis: _vm.degradedAnalysis,
          validAnalysis: _vm.validAnalysis,
          customSelectedCharts: _vm.customSelectedCharts,
          aggregates: _vm.aggregates,
          norms: _vm.norms,
          displayMetrics: _vm.displayMetrics
        },false)):_vm._e(),(_vm.isRunning)?_c('PatientResultsRunning',_vm._b({},'PatientResultsRunning',{
          analysis: _vm.analysis,
          aggregates: _vm.aggregates,
          segments: _vm.segments,
          norms: _vm.norms,
          displayMetrics: _vm.displayMetrics,
          resultsTab: _vm.resultsTab,
          validAnalysis: _vm.validAnalysis,
          scenario: _vm.scenario
        },false)):_vm._e(),(_vm.isRehab)?_c('PatientResultsRehab',_vm._b({},'PatientResultsRehab',{ analysis: _vm.analysis, aggregates: _vm.aggregates, displayMetrics: _vm.displayMetrics, segments: _vm.segments },false)):_vm._e(),(_vm.validAnalysis)?_c('PatientResultsFooter',_vm._b({},'PatientResultsFooter',{ analysis: _vm.analysis, degradedAnalysis: _vm.degradedAnalysis, analysisType: _vm.analysisType },false)):_vm._e()],1),_c('DialogAnalysisPathologyCustomSelect',{on:{"setSelectedCharts":function (data) { return _vm.setSelectedCharts(data); }}}),_c('DialogAnalysisConditionsPainsUpdate'),_c('DialogAnalysisConditionsWalkingAidsUpdate'),_c('DialogAnalysisConditionsShoesTypeUpdate')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }