



















































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, ref } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import PatientResultSummary from '@/components/patient/results/PatientResultSummary.vue';
import ChartWalkingContainerGroupBanner from '@/components/charts/walking/containers/ChartWalkingContainerGroupBanner.vue';
import ChartWalkingContainerGroupStepsAngles from '@/components/charts/walking/containers/ChartWalkingContainerGroupStepsAngles.vue';
import ChartWalkingContainerSymmetry from '@/components/charts/walking/containers/ChartWalkingContainerSymmetry.vue';
import ChartWalkingContainerCadence from '@/components/charts/walking/containers/ChartWalkingContainerCadence.vue';
import ChartWalkingContainerSpeed from '@/components/charts/walking/containers/ChartWalkingContainerSpeed.vue';
import ChartWalkingContainerDistance from '@/components/charts/walking/containers/ChartWalkingContainerDistance.vue';
import ElementInterpretationManualBanner from '@/components/elements/ElementInterpretationManualBanner.vue';
// Import utils ------------------------------------------------------------------------------------
import { useInterpretationManual } from '@/utils/interpretation-manual.utils';
import { usePatient } from '@/utils/patient.utils';
import ChartWalkingContainerGaitLine from '@/components/charts/walking/containers/ChartWalkingContainerGaitLine.vue';
import ChartWalkingContainerStanceTime from '@/components/charts/walking/containers/ChartWalkingContainerStanceTime.vue';
import ChartWalkingContainerPropulsionRatio from '@/components/charts/walking/containers/ChartWalkingContainerPropulsionRatio.vue';
import ChartWalkingContainerStrideLength from '@/components/charts/walking/containers/ChartWalkingContainerStrideLength.vue';
import ChartWalkingContainerClearanceSteppage from '@/components/charts/walking/containers/ChartWalkingContainerClearanceSteppage.vue';
import ChartStandardContainerSliderAdvanced from '@/components/charts/standard/containers/ChartStandardContainerSliderAdvanced.vue';
import i18n from '@/plugins/i18n';
import ChartWalkingContainerSwingTime from '@/components/charts/walking/containers/ChartWalkingContainerSwingTime.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'PatientResultsWalkingCustom',
  components: {
    PatientResultSummary,
    ChartWalkingContainerGroupBanner,
    ChartWalkingContainerSymmetry,
    ChartWalkingContainerCadence,
    ChartWalkingContainerSpeed,
    ChartWalkingContainerDistance,
    ElementInterpretationManualBanner
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    },
    norms: {
      type: Object,
      required: true
    },
    selectedCharts: {
      type: Array,
      required: true
    }
  },
  setup(properties) {
    const { data: patient } = usePatient();
    const { dontShowManualInterpretation } = useInterpretationManual();

    const charts: any = ref([
      {
        id: 'stance-time',
        chart: ChartWalkingContainerStanceTime,
        cols: 12,
        md: 6,
        lg: 6,
        xl: 6
      },
      {
        id: 'swing-time',
        chart: ChartWalkingContainerSwingTime,
        advanced: true,
        cols: 12,
        md: 6,
        lg: 6,
        xl: 6
      },
      {
        id: 'stride-duration',
        chart: ChartStandardContainerSliderAdvanced,
        props: {
          title: i18n.t('commons.standards.stride-duration'),
          aggregates: properties.aggregates.walking_stride_time,
          norms: properties.norms.walking_stride_time.bounds,
          unit: 'ms'
        }
      },
      {
        id: 'stride-length',
        chart: ChartWalkingContainerStrideLength,
        cols: 12,
        md: 6,
        lg: 6,
        xl: 6
      },
      {
        id: 'clearance-steppage',
        chart: ChartWalkingContainerClearanceSteppage,
        cols: 12,
        md: 6,
        lg: 6,
        xl: 6
      },
      {
        id: 'propulsion-rate',
        chart: ChartWalkingContainerPropulsionRatio,
        cols: 12,
        md: 6,
        lg: 6,
        xl: 6
      },
      {
        id: 'prosup-angles',
        chart: ChartWalkingContainerGroupStepsAngles,
        props: { globalView: true },
        cols: 12,
        md: 12,
        lg: 12,
        xl: 12
      },
      {
        id: 'gait-line',
        chart: ChartWalkingContainerGaitLine,
        advanced: true,
        cols: 12,
        md: 8,
        lg: 8,
        xl: 8
      }
    ]);

    const selectedCustomCharts = computed(() =>
      charts.value.filter((x: any) => properties.selectedCharts.includes(x.id))
    );

    return {
      // Values
      selectedCustomCharts,
      patient,
      // Flags
      dontShowManualInterpretation
    };
  }
});
