












// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, computed } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartContainer from '@/components/charts/ChartContainer.vue';
import ChartRunningStrikePatternAdvanced from '@/components/charts/running/ChartRunningStrikePatternAdvanced.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartRunningContainerStrikePattern',
  components: {
    ChartContainer,
    ChartRunningStrikePatternAdvanced
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    const valid = computed(
      () =>
        properties.displayMetrics.aggregates?.running_strike_pattern &&
        properties.displayMetrics.aggregates?.running_plantar_flexion_angle_foot_in &&
        properties.displayMetrics.aggregates?.running_impact_force
    );

    return {
      valid
    };
  }
});
