




































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, PropType, reactive, watch } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import PatientResultVideos from '@/components/patient/results/PatientResultVideos.vue';
import ChartRunningContainerGroupBanner from '@/components/charts/running/containers/ChartRunningContainerGroupBanner.vue';
import ChartRunningContainerSpeedCadence from '@/components/charts/running/containers/ChartRunningContainerSpeedCadence.vue';
import ChartRunningContainerAsymmetry from '@/components/charts/running/containers/ChartRunningContainerAsymmetry.vue';
import ChartRunningContainerStrikePattern from '@/components/charts/running/containers/ChartRunningContainerStrikePattern.vue';
import ChartRunningContainerRunProfile from '@/components/charts/running/containers/ChartRunningContainerRunProfile.vue';
import ChartRunningContainerGroupStepsAngles from '@/components/charts/running/containers/ChartRunningContainerGroupStepsAngles.vue';
//
import ElementInterpretationManualBanner from '@/components/elements/ElementInterpretationManualBanner.vue';
import PatientResultSummary from '@/components/patient/results/PatientResultSummary.vue';
// Import utils ------------------------------------------------------------------------------------
import { useInterpretationManual } from '@/utils/interpretation-manual.utils';
import { useAnalytics } from '@/utils/analytics.utils';
// Import types ------------------------------------------------------------------------------------
import { AnalysisEntityCompleted } from '@/plugins/podocore/repositories/analysis.repository';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'PatientResultsRunningGeneral',
  components: {
    PatientResultVideos,
    ChartRunningContainerSpeedCadence,
    ChartRunningContainerGroupBanner,
    ChartRunningContainerAsymmetry,
    ChartRunningContainerStrikePattern,
    ChartRunningContainerRunProfile,
    ChartRunningContainerGroupStepsAngles,
    ElementInterpretationManualBanner,
    PatientResultSummary
  },
  props: {
    analysis: {
      type: Object as PropType<AnalysisEntityCompleted>,
      required: true
    },
    aggregates: {
      type: Object,
      required: true
    },
    segments: {
      type: Object,
      required: true
    },
    norms: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    },
    scenario: {
      type: Object,
      required: true
    }
  },
  setup() {
    const { trackSuccess } = useAnalytics();
    const { dontShowManualInterpretation } = useInterpretationManual();
    const charts = reactive({
      angles: {
        advanced: false,
        relative: false
      }
    });

    const analyticsData = {
      speedCadence: {
        type: 'PatientAnalysis/Overview/Cadence-speed/Tooltip',
        analysisType: 'running'
      },
      asymmetry: {
        type: 'PatientAnalysis/Overview/Asymmetry/Tooltip',
        analysisType: 'running'
      },
      strikePattern: {
        type: 'PatientAnalysis/Overview/Strike pattern/Tooltip',
        analysisType: 'running'
      },
      runningProfile: {
        type: 'PatientAnalysis/Overview/running profile/Tooltip',
        analysisType: 'running'
      }
    };

    const relativeAngles = computed(() => charts.angles.relative);
    watch(relativeAngles, (value) => {
      trackSuccess('PatientAnalysis/Overview/Strike angulation/status', {
        type: value ? 'relative' : 'absolute',
        analysisType: 'running'
      });
    });

    const advancedAngles = computed(() => charts.angles.advanced);
    watch(advancedAngles, (value) => {
      trackSuccess('PatientAnalysis/Overview/Strike angulation/status', {
        type: value ? 'advanced' : 'normal',
        analysisType: 'running'
      });
    });

    return {
      dontShowManualInterpretation,
      charts,
      // Analytics
      analyticsData
    };
  }
});
