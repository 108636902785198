













// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, ref } from '@vue/composition-api';
// Import components -------------------------------------------------------------------------------
import ChartContainer from '@/components/charts/ChartContainer.vue';
import ChartWalkingFootProgressionAngleCircumduction from '@/components/charts/walking/ChartWalkingFootProgressionAngleCircumduction.vue';
import PatientResultsChartInterpretations from '@/components/patient/results/interpretations/PatientResultsChartInterpretations.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'ChartWalkingContainerFootProgressionAngleCircumduction',
  components: {
    ChartWalkingFootProgressionAngleCircumduction,
    PatientResultsChartInterpretations,
    ChartContainer
  },
  props: {
    aggregates: {
      type: Object,
      required: true
    },
    displayMetrics: {
      type: Object,
      required: true
    },
    norms: {
      type: Object
    }
  },
  setup(properties) {
    const valid = computed(
      () =>
        properties.displayMetrics.aggregates.walking_foot_progression_angle_flat_foot &&
        properties.displayMetrics.aggregates.walking_swing_width
    );

    const relative = ref(false);
    const advanced = ref(false);

    return {
      // Valid
      valid,
      // Values
      relative,
      advanced
    };
  }
});
