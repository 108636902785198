






















































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, ref, Ref } from '@vue/composition-api';

// Import components -------------------------------------------------------------------------------
import CompositeDialog from '@/components/composite/CompositeDialog.vue';
// Import types ------------------------------------------------------------------------------------
import type { DialogId } from '@/plugins/podocore/modules/bus/bus.module';
// -------------------------------------------------------------------------------------------------

export type TChartList = {
  [key: string]: any;
  title: string | string[];
  subtitle: string;
  selected: boolean;
  chartsList?: TchartsList[];
};
export type TchartsList = {
  [key: string]: any;
  title: string;
  selected: boolean;
};
export default defineComponent({
  name: 'DialogAnalysisPathologyCustomSelect',
  components: { CompositeDialog },
  setup(_, { emit }) {
    const dialogId: DialogId = 'analysis-pathology-custom-select';
    const rDialog: any = ref(null);

    const chartsList: Ref<TChartList[]> = ref([
      {
        title: 'commons.standards.walking-phase',
        subtitle: 'commons.standards.walking-phase.subtitle',
        selected: false,
        chartsList: [
          { id: 'stance-time', title: 'commons.standards.contact-time', selected: false },
          { id: 'swing-time', title: 'commons.standards.flight-time', selected: false },
          { id: 'stride-duration', title: 'commons.standards.stride-duration', selected: false },
          { id: 'stride-length', title: 'commons.standards.stride-length', selected: false }
        ]
      },
      {
        id: 'clearance-steppage',
        title: ['commons.standards.steppage', 'commons.standards.clearance'],
        subtitle: 'commons.standards.steppage-clearance.subtitle',
        selected: false
      },
      {
        id: 'propulsion-rate',
        title: 'commons.standards.propulsion-rate',
        subtitle: 'commons.standards.propulsion-rate.subtitle',
        selected: false
      },
      {
        id: 'prosup-angles',
        title: 'commons.standards.angle-pro-sup',
        subtitle: 'commons.standards.angle-pro-sup.subtitle',
        selected: false
      },
      {
        id: 'gait-line',
        title: 'commons.standards.gait-line',
        subtitle: 'commons.standards.gait-line.subtitle',
        selected: false
      }
    ]);

    const chartsSelected = computed(() => {
      const charts: string[] = [];
      for (const chart of chartsList.value) {
        if (chart.chartsList) {
          for (const subchart of chart.chartsList) {
            if (subchart.selected) charts.push(subchart.id);
          }
        } else if (chart.selected) charts.push(chart.id);
      }
      return charts;
    });

    function selectAllSubChart(chartsListIndex: number) {
      for (const chart of chartsList.value[chartsListIndex].chartsList!)
        chart.selected = chartsList.value[chartsListIndex].selected;
    }
    function unselectChart(chartsListIndex: number) {
      chartsList.value[chartsListIndex].selected = false;
    }

    function setSelectedCharts() {
      emit('setSelectedCharts', chartsSelected.value);
      closeDialog();
    }

    function closeDialog() {
      rDialog.value.close();
    }

    return {
      chartsList,
      rDialog,
      dialogId,
      chartsSelected,
      closeDialog,
      selectAllSubChart,
      unselectChart,
      setSelectedCharts
    };
  }
});
